import React from "react"
import { Link } from "gatsby"
import ArrowRight from "../../../../assets/img/arrow-right-white.inline.svg"

const DarkEntryBadges = props => {
  if (props.isBusiness) {
    return (
      <div className="flex">
        <a
        href="https://kudabusiness.onelink.me/epwz/jceed731/"
        className="dark-cta cta_button"
        target="_blank"
        rel="nofollow noopener noreferrer"
      >
        <span className="mr-3">{props.buttonText ? props.buttonText:"Get an account"}</span>
        <ArrowRight />
      </a>
      </div>
    )
  }

  return (
    // !props.hideDownload && (
    <div
      className={`kuda-get--wrap ${
        typeof props.className !== "undefined" ? props.className : ""
      }`}
    >
      {!props.isAmbassador ? (
        <Link to={"/joinKuda/"} className="kuda-cta-long mt-4  dark-cta kuda_web">
          Join Kuda
        </Link>
      ) : (
        <a
          href={props.ambassadorUrl}
          className="kuda-cta-long mt-4  dark-cta  kuda_web"
          target="_blank"
          rel="noopener noreferrer"
        >
          Join Kuda
        </a>
      )}
    </div>
  )
  // )
}
export default React.memo(DarkEntryBadges)
