import React from "react";
import { Img } from "react-image"
import paystack from '../../../../../../assets/img/kuda-business-paystack.svg';
import nomba from '../../../../../../assets/img/kuda-business-nomba.svg';
import sportybet from '../../../../../../assets/img/kuda-business-sportyBet.svg';
import bamboo from '../../../../../../assets/img/kuda-business-bamboo.svg';
import edukoya from '../../../../../../assets/img/kuda-business-edukoya.svg';
import norebase from '../../../../../../assets/img/kuda-business-norebase.svg';
import tribapay from '../../../../../../assets/img/kuda-business-tribapay.svg';
import rekija from '../../../../../../assets/img/kuda-business-rekija.svg';
import { TopAfricianBusinessStyled } from '../styles/trustedBusinessStyle';

const TrustedAfricanBusiness = (props) => {

    return (

        <TopAfricianBusinessStyled>
            <div className="kuda-top--inner">
                <p className="top-business--heading">Trusted by top african businesses</p>
            </div>



            <div className="business-sponsors--section">
                <div className="business-sponsor--inner">
                    <Img alt="paystack" className=""
                        src={paystack}
                    />
                    <Img alt="nomba" className=""
                        src={nomba}
                    />
                    <Img alt="sportybet" className=""
                        src={sportybet}
                    />
                    <Img alt="bamboo" className=""
                        src={bamboo}
                    />
                    <Img alt="edukoya" className=""
                        src={edukoya}
                    />
                    <Img alt="norebase" className=""
                        src={norebase}
                    />

                    <Img alt="tribapay" className=""
                        src={tribapay}
                    />
                    <Img alt="rekija" className=""
                        src={rekija}
                    />

                    <Img alt="paystack" className=""
                        src={paystack}
                    />
                    <Img alt="nomba" className=""
                        src={nomba}
                    />
                    <Img alt="sportybet" className=""
                        src={sportybet}
                    />
                    <Img alt="bamboo" className=""
                        src={bamboo}
                    />
                    <Img alt="edukoya" className=""
                        src={edukoya}
                    />
                    <Img alt="norebase" className=""
                        src={norebase}
                    />

                    <Img alt="tribapay" className=""
                        src={tribapay}
                    />
                    <Img alt="rekija" className=""
                        src={rekija}
                    />
                </div>
            </div>
        </TopAfricianBusinessStyled>
    )
}

export default TrustedAfricanBusiness;