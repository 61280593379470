import React from "react"
import Business from "../../../components/body/pages/en-ng/business"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const BusinessPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/business/"}
      title="Smarter Business Banking from Kuda | Kuda Business"
      description="Send invoices, make free transfers, manage multiple accounts & free up time for the rest of your life with smarter business banking from Kuda. Join today."
    />
    <Business />
  </Layout>
)

export default BusinessPage
