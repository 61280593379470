import styled from 'styled-components'

export const TestimonialStyle = styled.div`
.testimonial-extras img {
  width: 50px;
  height: 50px;
  border-radius: 100px;
}

.testimonial-extras {
  margin-top: 32px;
}

.testimonial-extras p {
  margin-left: 10px !important;
}

.kuda-testimonials--section {
  max-width: 100vw;
}
`