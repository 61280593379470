import React, { useEffect, Fragment } from "react"
import Testimonials from "./components/testimonials"
import DashboardIcon from "../../../../../assets/img/light-dashboard.inline.svg"
import SoftPOSIcon from "../../../../../assets/img/kuda-softpos.inline.svg";
import TimeIcon from "../../../../../assets/img/time-light.inline.svg"
import BusinessEntryIllustration from "../../../../../assets/img/kuda-business-illustration.svg"
import BusinessEntryBlur from "../../../../../assets/img/business/blur/kuda-business-hero-blur.inline.svg"
import ImageDarkTextRightWithButton from "./components/imageDarkTextRightWithButton"
import ImageDarkTextLeftWithButton from "./components/imageDarkTextLeftWithButton"
import CTABusiness from "../../general/ctaBusiness"
import DarkBelowFoldCards from "../../general/darkBelowFoldCards"
import HomeHeaderFold from "./components/homeHeaderFold"
import TrustedAfricanBusiness from "./components/trustedAfricanBusiness"
import BusinessRegistrationPlan from "./components/business-registration"
import { scrollToElement } from "../../../../utility/utils"
import MoreForYouComponent from "../../general/more-for-you";
import { moreForYou, moreForYou1 } from "./features";

// entry content images
import RegisterBusinessIllustration from "../../../../../assets/img/business/registration/RegisterBusiness-illustration.svg"

// no paper work images
import PaperlessIllustration from "../../../../../assets/img/business/no_paperwork/Paperwork-illustration.svg"

// expenses images
import ExpensesIllustration from "../../../../../assets/img/business/expenses/expenses-illustration.svg"

// multiple accounts images
import MultipleAccountIllustration from "../../../../../assets/img/business/multiple_accounts/multipleAccount-illustration.svg"

// invoicing images
import InvoicingIllustration from "../../../../../assets/img/business/Invoicing-illustration.svg"


import { Img } from "react-image";

const entryContent = {
  title: "The all-in-one business manager you need.",
  subtitle: "Send invoices, receive POS payments, pay business bills, make bulk transfers, and free up time for the rest of your life.",
  illustration: BusinessEntryIllustration,
  fallback: <BusinessEntryBlur />
}

const businessTopFeatures = [
  {
    icon: <DashboardIcon />,
    text: "Create and manage multiple business accounts.",
  },
  {
    icon: <SoftPOSIcon />,
    text: "Turn your smartphone into a POS with Kuda softPOS.",
  },
  {
    icon: <TimeIcon />,
    text: "Send up to ₦250 million daily.",
  },
]

const withoutPaperWork = {
  title: "Open a Kuda Business account without paperwork.",
  subtitle: "Wherever you are, you can set up your account quickly.",
  url: "",
  name: "",
  illustration: (
    <Img src={PaperlessIllustration} className="kuda-business-image" />

  ),
}

const invoicing = {
  title: "Get paid quicker.",
  subtitle: "Send estimates and invoices with multiple payment options.",
  url: "https://kudabusiness.onelink.me/epwz/jceed731",
  name: "Try invoicing now",
  illustration: (
    <Img src={InvoicingIllustration} className="kuda-business-image" />

  ),
}

const registerBusiness = {
  title: "Register your business to grow!",
  subtitle: "Get your CAC registration documents and your Tax Identification Number (TIN) easily through your Kuda Business account.",
  url: "https://kudabusiness.onelink.me/epwz/jceed731",
  name: "Start business registration",
  illustration: (
    <Img src={RegisterBusinessIllustration} className="kuda-business-image" />

  ),
}

const expenses = {
  title: "Take care of all your business expenses in one place.",
  subtitle:
    "Pay bills and buy airtime easily without switching platforms, keep yourself organised and your business running smoothly.",
  url: "https://kudabusiness.onelink.me/epwz/jceed731",
  name: "Explore bill payments",
  illustration: (
    <Img src={ExpensesIllustration} className="kuda-business-image" />

  ),
}

const manageMultipleAccount = {
  title: "Manage multiple Kuda accounts from one admin profile.",
  subtitle:
    "You wear different hats, your business account should too. Set up multiple accounts and control them all with a single sign-in.",
  url: "",
  name: "",
  illustration: (
    <Img src={MultipleAccountIllustration} className="kuda-business-image" />

  ),
}

const Business = () => {
  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })
  }, [])

  return (
    <Fragment>
      <HomeHeaderFold
        title={entryContent.title}
        subtitle={entryContent.subtitle}
        illustration={entryContent.illustration}
        isImage={true}
        fallback={entryContent.fallback}
      />
      <TrustedAfricanBusiness />

      <DarkBelowFoldCards topFeatures={businessTopFeatures} />

      <ImageDarkTextLeftWithButton
        title={withoutPaperWork.title}
        subtitle={withoutPaperWork.subtitle}
        illustration={withoutPaperWork.illustration}
        name={withoutPaperWork.name}
        url={withoutPaperWork.url}
      // isExternal={true}
      />
      <ImageDarkTextRightWithButton
        title={invoicing.title}
        subtitle={invoicing.subtitle}
        illustration={invoicing.illustration}
        name={invoicing.name}
        url={invoicing.url}
        isExternal={true}
      />
      <ImageDarkTextLeftWithButton
        title={registerBusiness.title}
        subtitle={registerBusiness.subtitle}
        illustration={registerBusiness.illustration}
        name={registerBusiness.name}
        url={registerBusiness.url}
        isExternal={true}
      />
      <ImageDarkTextRightWithButton
        title={expenses.title}
        subtitle={expenses.subtitle}
        illustration={expenses.illustration}
        name={expenses.name}
        url={expenses.url}
        isExternal={true}
      />
      <ImageDarkTextLeftWithButton
        title={manageMultipleAccount.title}
        subtitle={manageMultipleAccount.subtitle}
        illustration={manageMultipleAccount.illustration}
        name={manageMultipleAccount.name}
        url={manageMultipleAccount.url}
      // isExternal={true}
      />
      <BusinessRegistrationPlan />
      <Testimonials />
      <MoreForYouComponent moreForYou={moreForYou1} />
      <CTABusiness />
    </Fragment>
  )
}

export default Business
