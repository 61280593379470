import React from "react";
import DarkEntryBadges from "../../../general/darkBadges";
import License from "../../../general/licenses";
import { HomeHeaderFoldStyled } from "../styles/InnerPagesStyle";
import { Img } from "react-image"



const HomeHeaderFold = (props) => {

    return (
        <HomeHeaderFoldStyled>
            <div className="kuda-entry--fold home-fold business-home-fold kuda-ignore--container career-noMarginTop" >
                <div className="kuda-entry--inner flex justify-between align-center mx-auto career-container-width" >
                    <div className="kuda-section--45 career-header-padding">
                        <div className="kuda-text--wrap business-text-wrap">
                            <h1 className={`business-header-title kuda-main--heading text-xlbold color-black slide-in-right ${props.isBusiness ? "text-lg-biz" : ""}`}>
                                {props.title}
                            </h1>

                            <p
                                className={`card-main--heading text-semi-bold color-black title-bottom--spacing slide-in-right delay-2 ${props.isDownload ? "desktop-only" : ""
                                    } ${props.isBusiness ? "card-main--heading-biz" : ""}`}
                            >
                                {props.subtitle}
                            </p>
                            <div className="slide-in-right delay-4">
                                <License className="bottom--spacing" />
                                <div className="mobile-only w-100 flex justify-center mt-5">
                                    <DarkEntryBadges isBusiness buttonText={"Open a Kuda Business Account"} />
                                </div>
                                <div className="desktop-only mt-5">
                                    <DarkEntryBadges isBusiness buttonText={"Open a Kuda Business Account"} />
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="kuda-section--55">
                        <div className={`kuda-business-illustration--center slide-in-top delay-6`}>

                            <div className="kuda-section--business-illustration">
                                <Img alt="kuda Microfinance Bank" className="kuda--business-illustration"
                                    src={props.illustration}
                                    loader={props.fallback}
                                    loading="eager"
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </HomeHeaderFoldStyled>
    )
}

export default HomeHeaderFold;
