import styled from "styled-components";


export const TopAfricianBusinessStyled = styled.div`
    width: 110%;
    height: 200px;
    position: relative;
    padding: 56px 80px 56px 80px;
    background: black;
    border-radius: 10px;
    margin-bottom: 4rem;
    left: -5%;
    overflow: hidden;

.kuda-top--inner {
    display: flex;
    justify-content: center;
}

.top-business--heading {
    font-size: 20px;
    line-height: 24px;
    font-weight: 900;
    color: var(--whiteColor);
    text-transform: capitalize;
    margin-bottom: 2.5rem;
}

.business-sponsors--section {
    display: flex;
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
}

.business-sponsor--inner{
    display: flex;
    justify-content: center;
    gap: 3.5rem;
    transform: translate3d(0px, 0px, 0px);
    min-width: 500px;
    animation: 120s linear 0s infinite normal none running nonStop;
    -webkit-animation: 120s linear 0s infinite normal none running nonStop;
}
@media only screen and (max-width: 1024px){
     top: -50px;
     margin-bottom: 0px;
     width: 106%;
     left: -3%;
}

@media only screen and (max-width: 991px){
    top: -80px;
    width: 100%;
    left: 0%;
}   

@media only screen and (max-width: 768px){
    top: -100px;
} 

@media only screen and (max-width: 767px){

    top: 0px;
    margin-bottom: 0px;
    width: 105%;
    height: 160px;
    left: -2%;
    margin-top: 2.5rem;
    padding: 32px 0px 32px 0px;

    .top-business--heading {
        font-size: 16px;
    }
}

`